import React from "react";
import MUIDataTable from "mui-datatables";

export default function UsersTable(props) {
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
      },
    },
    {
      name: "firstName",
      label: "Voornaam",
    },
    {
      name: "lastName",
      label: "Achternaam",
    },
    {
      name: "email",
      label: "Email",
    },
  ];

  let tableData = [];

  props.data.users.forEach(function (user) {
    tableData.push({
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    });
  });

  const onRowClick = (selected) => {
    const clickedUser = props.data.users.find(
      (user) => user.id === selected[0]
    );
    props.onRowClick(clickedUser);
  };

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    onRowClick: onRowClick,
  };

  return (
    <MUIDataTable
      title={"Gebruikers"}
      data={tableData}
      columns={columns}
      options={options}
    />
  );
}
