import { languageAction } from "../actions";

const language = (state = "nl", action) => {
  switch (action.type) {
    case languageAction.SET_LANGUAGE:
      console.log(action);
      return action.language;
    default:
      return state;
  }
};

export default language;
