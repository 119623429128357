import React, { useEffect, useRef, useState } from "react";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "@apollo/react-hooks";
import { SEARCH_PRODUCTS } from "../queries-mutations/products";
import SingleSearchResult from "./SingleSearchResult";
import Popper from "@material-ui/core/Popper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Translate } from "react-auto-translate";
import { connectAdvanced } from "react-redux";

const headerMenu = makeStyles(theme => ({
  searchContainer: {
    // padding: "1px 2px 1px 10px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    dropShadow: 0,
    // border: '2px solid #167c5f',
    backgroundColor: "#f6f6f6",
    borderRadius: 3,
    // boxShadow: "none",
  },
  searchInput: {
    width: "90%",
    padding: "6px 6px 7px",
    boxShadow: "none"
  },
  searchIcon: {},
  genericIcon: {
    fontSize: 30
  },
  hideOnMobile: {
    "@media (max-width: 768px)": {
      display: "none"
    }
  },
  hideOnDesktop: {
    "@media (min-width: 769px)": {
      display: "none"
    }
  },
  results: {
    padding: "5px 10px",
    marginTop: 10,
    // minWidth: 240,
    // maxWidth: 240, // "100vw !important",
    maxHeight: "80vh",
    overflowY: "scroll",
    '&::-webkit-scrollbar': {
      width: 0,
    },
       scrollbarWidth: 'none',  
    '-ms-overflow-style': 'none',  
  }
}));

export default function SearchBar( props ) {
  const classes = headerMenu();

  const [searchTerm, setSearchTerm] = useState("");
  const [focus, setFocus] = useState(null);
  const inputRef = useRef(null);

  const handleFocus = event => {
    setFocus(event.currentTarget);
  };

  const handleBlur = () => {
    setFocus(null);
  };

  const { loading, error, data } = useQuery(SEARCH_PRODUCTS, {
    variables: { q: searchTerm }
  });

  const open = Boolean(focus);
  // const open = Boolean(true);

  const id = open ? "simple-popover" : undefined;

  const search = e => {
    setSearchTerm(e.target.value);
  };


  return (
    <Paper component="form" className={classes.searchContainer} ref={inputRef}>
      <InputBase
        className={classes.searchInput}
        placeholder="Zoeken..."
        disableElevation
        onChange={search}
        onFocus={handleFocus}
        onBlur={() => {
          setTimeout(function() {
            handleBlur();
          }, 500);
        }}
        
      />
      <IconButton
        type="submit"
        className={classes.searchIcon}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
      <Popper id={id} open={open && searchTerm.length >= 1 } anchorEl={focus} style={{ zIndex: 100 }}>
        <Paper className={classes.results} 
        
        style={{ 
          maxWidth: inputRef.current?.offsetWidth < window.innerWidth 
            ? inputRef.current.offsetWidth - 20
            : window.innerWidth,  
          minWidth: inputRef.current?.offsetWidth < window.innerWidth 
            ? inputRef.current.offsetWidth - 20
            : window.innerWidth ,
            maxHeight: window.innerWidth > 600 ? "80vh" : "60vh",
        }}
        >
          {loading ? (
            <CircularProgress />
          ) 
          : searchTerm.length < 1 ? (
            <div>
              <Translate>Begin met zoeken</Translate>
            </div>
          ) 
          : data   ? 
            data.searchProducts.length > 0 ? (
              data.searchProducts.map(product => (
                <SingleSearchResult product={product} />
              ))
            ):(
              <div>
              <Translate>Geen resulta</Translate>
            </div>
            )
         
           : 
          (
            <div>
              <Translate>Er ging iets fout</Translate>
            </div>
          )}
        </Paper>
      </Popper>
    </Paper>
  );
}
