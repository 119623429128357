import React from "react";
import { Multiselect } from "multiselect-react-dropdown";
import { useQuery } from "@apollo/react-hooks";
import { categoriesMinimal } from "../queries-mutations/products";

export default function CategoriesSelector(props) {
  const { loading, error, data } = useQuery(categoriesMinimal);
  //console.log(data.categories);
  // data.categories = data.categories.filter(category => {
  //   return category.published;
  // });
  // console.log(data);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error! ${error.message}</div>;

  return (
    <Multiselect
      options={data.categories.filter(category => {
        return category.published;
      })} // Options to display in the dropdown
      selectedValues={props.selectedCategories} // Preselected value to persist in dropdown
      onSelect={props.onSelectCategory} // Function will trigger on select event
      onRemove={props.onRemoveCategory} // Function will trigger on remove event
      displayValue="name" // Property name to display in the dropdown options
    />
  );
}
