const userInformation = (
  state = {
    firstName: "",
    lastName: "",
    id: "",
    code: "",
    email: "",
    auth: false,
    duiten: 0
  },
  action
) => {
  switch (action.type) {
    case "LOGIN_USER":
      return {
        firstName: action.firstName,
        lastName: action.lastName,
        id: action.id,
        code: action.code,
        email: action.email,
        auth: true,
        duiten: action.duiten
      };
    case "SET_DUITEN":
      return {
        firstName: state.firstName,
        lastName: state.lastName,
        id: state.id,
        code: state.code,
        email: state.email,
        auth: state.auth,
        duiten: action.duiten
      };
    case "LOGOUT_USER":
      return {
        firstName: "",
        lastName: "",
        id: "",
        code: "",
        email: "",
        auth: false
      };
    default:
      return state;
  }
};

export default userInformation;
