import React from "react";
import MUIDataTable from "mui-datatables";

export default function CompaniesTable(props) {
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        filterType: "textField"
      }
    },
    {
      name: "name",
      label: "Naam",
      options: {
        filterType: "textField"
      }
    },
    {
      name: "code",
      label: "Code",
      options: {
        filterType: "textField"
      }
    },
    {
      name: "duiten",
      label: "Duiten",
      options: {
        filterType: "multiselect"
      }
    },
    {
      name: "endDate",
      label: "Eind Datum",
      options: {
        filterType: "multiselect"
      }
    },
    {
      name: "customers",
      label: "Aantal",
      options: {
        filterType: "multiselect"
      }
    },
    {
      name: "firsttax",
      label: "9% btw",
      options: {
        filterType: "multiselect",
        display: false
      }
    },
    {
      name: "secondtax",
      label: "21% btw",
      options: {
        filterType: "multiselect",
        display: false
      }
    },

  ];

  let tableData = [];

  props.data.companies.forEach(function(company) {
    tableData.push({
      id: company.id,
      name: company.name,
      code: company.code,
      duiten: company.duiten,
      endDate: company.endDate,
      customers: company.employees.length,
      firsttax: company.firsttax,
      secondtax: company.secondtax
    });
  });

  const onRowClick = selected => {
    props.onRowClick(selected[0]);
  };

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    onRowClick: onRowClick
  };

  return (
    <MUIDataTable
      title={"Bedrijven"}
      data={tableData}
      columns={columns}
      options={options}
    />
  );
}
