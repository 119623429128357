export default function objectInArray(obj, list) {
  let i;
  for (i = 0; i < list.length; i++) {
    if (list[i].id) {
      if (list[i].id === obj.id) {
        return i;
      }
    }
  }

  return false;
}
