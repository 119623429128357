import React from "react";
import HeaderText from "./HeaderText";
import TranslationFlag from "./TranslationFlag";
import UsersHeaderMenu from "./UsersHeaderMenu";

export default function UsersHeader() {
  return (
    <div>
      <HeaderText />
      <UsersHeaderMenu />
    </div>
  );
}
