import makeStyles from "@material-ui/core/styles/makeStyles";

export const editModalStyle = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    width: 900,
    minHeight: 700,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  prodmodalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    width: 900,
    minHeight: 700,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY:"scroll",
    height:"80%"
  },
  catmodalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    width: 900,
    minHeight: 700,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY:"scroll",
    height:"80%"
  },
  smallModalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    width: 900,
    minHeight: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  largeModalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    width: "80%",
    minHeight: "80%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  input: {
    marginTop: 10,
  },
  lastInput: {
    marginBottom: 10,
  },
  parent: {
    overflowY: "scroll",
    margin: "5px, 0px",
  },
  marginLeft: {
    marginLeft: 25,
  },
  clear:{
    clear:"both",
  }
}));
