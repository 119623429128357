import React, { useState } from "react";
import { editModalStyle } from "../styles/editModalStyle";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { CREATE_USER, UPDATE_USER } from "../queries-mutations/adminAuth";
import { useMutation } from "@apollo/react-hooks";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

export default function EditUserModal(props) {
  const classes = editModalStyle();

  const [updateUser] = useMutation(UPDATE_USER);
  const [createUser] = useMutation(CREATE_USER);

  const [state, setState] = useState({
    firstName: props.user ? props.user.firstName : "",
    lastName: props.user ? props.user.lastName : "",
    email: props.user ? props.user.email : "",
    password: "",
  });

  const handleTextChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };
  console.log(state);

  const saveUser = () => {
    if (props.user.id) {
      updateUser({
        variables: {
          id: props.user.id,
          firstName: state.firstName,
          lastName: state.firstName,
          email: state.email,
          password: state.password,
        },
      }).then(() => {
        props.onClose();
      });
    } else {
      createUser({
        variables: {
          firstName: state.firstName,
          lastName: state.firstName,
          email: state.email,
          password: state.password,
          role:"ADMIN"
        },
      }).then(() => {
        props.onClose();
      });
    }
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        className={classes.parent}
      >
        <Paper className={classes.modalStyle}>
          {props.user ? (
            <h1>Gebruiker bijwerken</h1>
          ) : (
            <h1>Nieuwe gebruiker</h1>
          )}
          <form
            onSubmit={(event) => {
              event.preventDefault();
              saveUser();
            }}
          >
            <TextField
              variant="outlined"
              label="Voornaam"
              name="firstName"
              required
              defaultValue={state.firstName}
              className={classes.input}
              onChange={handleTextChange}
            />
            <br />
            <TextField
              variant="outlined"
              label="Achternaam"
              name="lastName"
              required
              defaultValue={state.lastName}
              className={classes.input}
              onChange={handleTextChange}
            />
            <br />
            <TextField
              variant="outlined"
              label="Email"
              name="email"
              required
              defaultValue={state.email}
              className={classes.input}
              onChange={handleTextChange}
            />
            <br />
            <TextField
              variant="outlined"
              label="Wachtwoord"
              name="password"
              required
              type="password"
              className={classes.input}
              onChange={handleTextChange}
            />
            <br />
            <Button
              variant="contained"
              color="primary"
              className={classes.input}
              type="submit"
            >
              Opslaan
            </Button>
          </form>
        </Paper>
      </Modal>
    </div>
  );
}
