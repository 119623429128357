import React from "react";
import { Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";

const styles = StyleSheet.create({
  page: {
    paddingTop: 65,
    paddingBottom: 65,
    paddingHorizontal: 35,
    paddingLeft:40
  },
  text:{
    fontSize: 16,
  },
  smallertext:{
    fontSize:14
  },
  rightsection:{
    flexGrow:1,
    marginLeft: 10,
    paddingLeft: 10,
  },
  leftsection: {
    marginRight: 60,
    paddingRight: 60,
    marginLeft:20,
    flexGrow: 1
  },
  sectionHorizontal: {
    flexDirection: "row",
    marginBottom: 5,
    marginTop: 5
  },
  horizontal:{
    flexDirection:"row",
  },
  orderDetail: {
    width: "30%"
  },
  orderDetailWide: {
    width: "50%"
  },
  orderDetailSmall: {
    width: "10%"
  },
  knob: {
    alignItems: "center",
    justifyContent: "center",
    width: "15px",
    height: "15px",
    borderWidth: 3,
    borderColor: "black",
    position: "absolute",
    backgroundColor: "white",
    fontSize: 8
  },

  boxContainer: {
    width: "10%"
  },
  doneByContainer: {
    borderBottom: "1px solid black",
    marginBottom: 20
  },
  fillableText: {
    width: "40%",
    fontSize:14
  },
  customsection: {
    margin:1
  }
});

export default function SingleOrderPickerList(props) {
  let totaal = 0;
  props.orders.map(singleOrder => {
    singleOrder.products.map(product => {
      totaal += product.price;
    });
  });
  console.log(props);
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.sectionHorizontal}>
        <View style={styles.leftsection}>
          <Text style={styles.text}>{props.companyName}</Text>
          {/* <Text style={styles.text}>{props.code}</Text> */}
          <Text style={styles.text}>
            {props.firstName}{" "}
            {props.insertion &&
              props.insertion.length > 0 &&
              `${props.insertion} `}
            {props.lastName}
          </Text>
        </View>
        <View style={styles.rightsection}>
        <Text style={styles.text}>{props.companyName}</Text>
          {/* <Text style={styles.text}>{props.code}</Text> */}
          <Text style={styles.text}>
            {props.firstName}{" "}
            {props.insertion &&
              props.insertion.length > 0 &&
              `${props.insertion} `}
            {props.lastName}
          </Text>
          <Text style={styles.text}>{props.email}</Text>
          <Text style={styles.smallertext}>Te besteden: {props.duiten}</Text>
          <Text style={styles.smallertext}>Uitgegeven: {totaal}</Text>
        </View>
      </View>
      <View style={styles.customsection}>

        </View>
      {props.orders.map(singleOrder => (
        <View style={styles.row}>

          <Text style={styles.smallertext}>
            Datum: {moment(singleOrder.createdAt).format("DD-MM-YYYY")}
          </Text>

          <View style={{marginTop: 20}}>
          <View style={styles.sectionHorizontal}>
            <View style={styles.boxContainer} />
            <View style={styles.orderDetail}>
              <Text style={styles.smallertext}>Code</Text>
            </View>
            <View style={styles.orderDetailWide}>
              <Text style={styles.smallertext}>Naam</Text>
            </View>
            <View style={styles.orderDetailSmall}>
              <Text style={styles.smallertext}>Prijs</Text>
            </View>
          </View>
          {singleOrder.products.map(
            product =>
              product.hasProduct && (
                <View style={styles.sectionHorizontal}>
                  <View style={styles.boxContainer}>
                    <View style={styles.knob}>
                      <Text style={{ fontSize: 8, marginTop: 4 }}> </Text>
                    </View>
                  </View>
                  <View style={styles.orderDetail}>
                    <Text style={styles.smallertext}>{product.hasProduct.code}</Text>
                  </View>
                  <View style={styles.orderDetailWide}>
                    <Text style={styles.smallertext}>{product.hasProduct.name}</Text>
                  </View>
                  <View style={styles.orderDetailSmall}>
                    <Text style={styles.smallertext}>{product.hasProduct.price}</Text>
                  </View>
                </View>
              )
          )}
        </View>
        </View>
      ))}

      <View style={styles.row}>
        <View style={styles.sectionHorizontal}>
          <Text style={styles.fillableText}>Gepickt door</Text>
          <Text style={styles.fillableText}>Gecontroleerd door</Text>
        </View>
      </View>
    </Page>
  );
}
