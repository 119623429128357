import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { categories } from "../queries-mutations/products";
import MUIDataTable from "mui-datatables";

export default function CategoriesTable(props) {
  const { loading, error, data } = useQuery(categories);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error! ${error.message}</div>;

  if (data) {
    const columns = [
      {
        name: "id",
        label: "id",
        options: {
          display: false,
          filterType: "textField"
        }
      },
      {
        name: "order",
        label: "order",
        options: {
          filter: false
        }
      },
      {
        name: "name",
        label: "Naam",
        options: {
          filterType: "textField"
        }
      },
      {
        name: "description",
        label: "Beschrijving",
        options: {
          filter: false
        }
      },
      {
        name: "productCount",
        label: "Aantal producten",
        options: {
          filterType: "multiselect"
        }
      }
    ];

    let tableData = [];

    // data.categories=data.categories.filter(category => {
    //   return category.published;
    // });

    data.categories.forEach(function(category) {
      let productsCount = category.products.length;
      tableData.push({
        id: category.id,
        name: category.name,
        order: category.order,
        published: category.published,
        description: category.description.replace(/(<([^>]+)>)/ig, ''),
        // author: category.author.firstName,
        productCount: productsCount
      });
    });

    const onRowClick = selected => {
      const selectedCategory = data.categories.find(
        category => category.id === selected[0]
      );
      console.log(selectedCategory);
      props.onRowClick(selectedCategory);
    };

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      onRowClick: onRowClick
    };

    return (
      <MUIDataTable
        title={"Categorieën (gepubliceerd)"}
        data={tableData.filter(category => {
          return category.published;
        })}
        columns={columns}
        options={options}
      />
    );
  }
}
