import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { categoriesMinimal } from "../queries-mutations/products";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

export default function CategorySelector(props) {
  const { loading, error, data } = useQuery(categoriesMinimal);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error! ${error.message}</div>;

  if(props.label){
   var label = props.label;
  }
  else{
   var label = "Subcategorie van";
  }
  return (
    <Autocomplete
      value={props.parent}
      id="parent_cat"
      onChange={props.onChange}
      // options={data.categories}
      options={data.categories.filter(category => {
        return category.published;
      })} 
      getOptionLabel={option => option.name}
      style={{ width: 300 }}
      renderInput={params => (
        <TextField {...params} label={label} variant="outlined" />
      )}
    />
  );
}
