import { connect } from "react-redux";
import { loginUser } from "../actions";

function LoginHelper(props) {
  props.dispatch(
    loginUser(
      props.customerByCode.firstName,
      props.customerByCode.lastName,
      props.customerByCode.id,
      props.customerByCode.email,
      props.customerByCode.code,
      props.customerByCode.duiten
    )
  );
  return null;
}

export default connect()(LoginHelper);
