import { adminActions } from "../actions";

const adminAuth = (
  state = {
    token: null
  },
  action
) => {
  switch (action.type) {
    case adminActions.SET_TOKEN:
      return { token: action.token };
    case adminActions.UNSET_TOKEN:
      return { token: null };
    default:
      return state;
  }
};

export default adminAuth;
