import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CompaniesTable from "../../components/CompaniesTable";
import AddNewButton from "../../components/AddNewButton";
import { adminRoutes } from "./constants";
import NewCompanyModal from "../../components/NewCompanyModal";
import { useQuery } from "@apollo/react-hooks";
import {
  ARCHIVEDCOMPANIES,
  COMPANIES
} from "../../queries-mutations/companies";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArchivedCompaniesTable from "../../components/ArchivedCompaniesTable";

export default function Bedrijven() {
  const [modal, setModal] = React.useState();

  const { loading, error, data, refetch } = useQuery(COMPANIES, {
    returnPartialData: false , errorPolicy: "ignore" 
  },{ errorPolicy: "all" });

  const [state, setState] = useState({
    firsttax: [],
    secondtax: [],
    total:'',
  });

  const {
    loading: archivedLoading,
    error: archivedError,
    data: archivedData,
    refetch: archivedRefetch
  } = useQuery(ARCHIVEDCOMPANIES, {
    returnPartialData: false , errorPolicy: "ignore" 
  },{ errorPolicy: "all" });
  
  if(!loading){
    if(data && data.companies.length >0){
    data.companies.map(company =>{
      company.tax = company.employees.map(emp =>emp).flat().map(order => order.order).flat().map(prod => (prod)? prod.products: '').flat();

    })
    data.companies.map(prod =>{
      state.firsttax = [];
      state.secondtax = [];
      prod.tax.map(product => {
        if(product){
        var productprice = product.price;
        if(product.hasproduct){
        product.hasProduct['productprice'] = productprice;
        if(product.hasProduct.taxpercent == 9){
        state.firsttax.push({price:product.hasProduct.productprice});
        }
        if(product.hasProduct.taxpercent == 21){
          state.secondtax.push({price:product.hasProduct.productprice});
        }
      }
      }
      });
      prod.firsttax = state.firsttax.reduce(function(prev, current) {
        return prev + +current.price
      }, 0);
      prod.secondtax = state.secondtax.reduce(function(prev, current) {
        return prev + +current.price
      }, 0);
    })
  }
}
  if(!archivedLoading){
    if(archivedData.archivedCompanies.length >0){
    archivedData.archivedCompanies.map(company =>{
      company.tax = company.employees.map(emp =>emp).flat().map(order => order.order).flat().map(prod => (prod)? prod.products: '').flat();

    })
    archivedData.archivedCompanies.map(prod =>{
      state.firsttax = [];
      state.secondtax = [];
      prod.tax.map(product => {
        if(product){
        var productprice = product.price;
        if(product.hasproduct){
        product.hasProduct['productprice'] = productprice;
        if(product.hasProduct.taxpercent == 9){
        state.firsttax.push({price:product.hasProduct.productprice});
        }
        if(product.hasProduct.taxpercent == 21){
          state.secondtax.push({price:product.hasProduct.productprice});
        }
      }
      }
      });
      prod.firsttax = state.firsttax.reduce(function(prev, current) {
        return prev + +current.price
      }, 0);
      prod.secondtax = state.secondtax.reduce(function(prev, current) {
        return prev + +current.price
      }, 0);
    })
  }
}
  const handleClose = () => {
    setModal(<div />);
    setTimeout(function() {
      refetch();
      archivedRefetch();
    }, 500);
  };

  const newCompany = () => {
    setModal(<NewCompanyModal open={true} onClose={handleClose} />);
  };

  const onRowClick = id => {
    const win = window.open(adminRoutes.ADMIN_BEDRIJF + id);
    win.focus();
  };

  return(
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          {loading && <CircularProgress color="primary" />}
          {error && <p>{error.message}</p>}
          {data && <CompaniesTable onRowClick={onRowClick} data={data} />}
        </Paper>
        <Paper style={{ marginTop: 25 }}>
          {archivedLoading && <CircularProgress color="primary" />}
          {archivedError && <p>{error.message}</p>}
          {archivedData && (
            <ArchivedCompaniesTable
              onRowClick={onRowClick}
              data={archivedData}
            />
          )}
        </Paper>
        <AddNewButton onClick={newCompany} />
      </Grid>
      {modal}
    </Grid>
  );
}
