import React from "react";
import Grid from "@material-ui/core/Grid";
import { useQuery } from "@apollo/react-hooks";
import { USERS } from "../../queries-mutations/adminAuth";
import EditUserModal from "../../components/EditUserModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddNewButton from "../../components/AddNewButton";
import UsersTable from "../../components/UsersTable";

export default function Gebruikers() {
  const [modal, setModal] = React.useState();

  const { loading, error, data, refetch } = useQuery(USERS, {
    partialRefetch: true,
  });

  const handleClose = () => {
    setModal(<div />);
    setTimeout(function () {
      refetch();
    }, 500);
  };

  const onRowClick = (clickedUser) => {
    setModal(
      <EditUserModal open={true} onClose={handleClose} user={clickedUser} />
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {loading && <CircularProgress color="primary" />}
        {error && <p>{error.message}</p>}
        {data && <UsersTable onRowClick={onRowClick} data={data} />}
        <AddNewButton onClick={onRowClick} />
        {modal}
      </Grid>
    </Grid>
  );
}
