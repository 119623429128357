import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { editModalStyle } from "../styles/editModalStyle";
import { tinyKey } from "../Keys/tiny";
import { Editor } from "@tinymce/tinymce-react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CategorySelector from "./CategorySelector";
import { useMutation } from "@apollo/react-hooks";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ConfirmationRequiredButton from "./ConfirmationRequiredButton";
import Switch from "@material-ui/core/Switch";
import {
  ADD_IMAGE_TO_CATEGORY,
  CONNECT_CATEGORY,
  CREATE_CATEGORY,
  CREATE_IMAGE,
  DISCONNECT_CATEGORY,
  REMOVE_IMAGE_FROM_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY
} from "../queries-mutations/products";
import ImageUploader from "react-images-upload";
import AdminImageDisplay from "./AdminImageDisplay";
import ImageUpload from "../helpers/ImageUpload";

export default function EditCategoryModal(props) {
  const [state, setState] = useState({
    name: props.category.name ? props.category.name : "",
    description: props.category.description ? props.category.description : "",
    order: props.category.order ? props.category.order : "",
    published: props.category.published ? props.category.published : false,
    parent: props.category.parent ? props.category.parent : {},
    oldImage: props.category.image ? [props.category.image] : [],
    removedImage: [],
    image: [],
  });

  const classes = editModalStyle();

  const [updateCategory] = useMutation(UPDATE_CATEGORY);
  const [connectCategory] = useMutation(CONNECT_CATEGORY);
  const [disconnectCategory] = useMutation(DISCONNECT_CATEGORY);
  const [createCategory] = useMutation(CREATE_CATEGORY);
  const [removeImageFromCategory] = useMutation(REMOVE_IMAGE_FROM_CATEGORY);
  const [addImageToCategory] = useMutation(ADD_IMAGE_TO_CATEGORY);
  const [createImage] = useMutation(CREATE_IMAGE);
  const [deleteCategory] = useMutation(DELETE_CATEGORY);

  const onDropImage = (image) => {
    setState({ ...state, image: [image] });
  };

  const handleCheckChange = (event) => {
    console.log(event.target.name);
    console.log(event.target.checked);
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const removeImage = (image) => {
    setState({ ...state, removedImage: [image] });
  };

  const saveCategory = () => {
    if (!!props.category.name) {
      updateCategory({
        variables: {
          id: props.category.id,
          name: state.name,
          description: state.description,
          order: parseInt(state.order),
          published: state.published,
        },
      });
      if (!!state.parent && !!state.parent.id) {
        connectCategory({
          variables: {
            id: props.category.id,
            parentId: state.parent.id,
          },
        });
      } else if (!!props.category.parent && !state.parent) {
        disconnectCategory({
          variables: {
            id: props.category.id,
          },
        });
      }
    } else {
      createCategory({
        variables: {
          name: state.name,
          description: state.description,
          published: state.published,
          parentID: state.parent.id,
          order: parseInt(state.order),
          authorID: "ckbjguxgm000x0706su23aqjc", //TODO get user ID
        },
      }).then((r) => console.log(r));
    }
    if (state.removedImage.length > 0) {
      removeImageFromCategory({
        variables: {
          categoryId: props.category.id,
        },
      });
    }
    state.image.forEach(async (image) => {
      let imageData = await ImageUpload(image[0]);
      createImage({
        variables: {
          location: imageData.data.name,
        },
      }).then((r) => {
        addImageToCategory({
          variables: {
            categoryId: props.category.id,
            imageId: r.data.createImage.id,
          },
        });
      });
    });
    props.onClose();
    //window.location.reload();
  };

  const handleTextChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleParentChange = (event, data) => {
    setState({ ...state, parent: data });
  };

  const handleDescriptionChange = (content, editor) => {
    setState({ ...state, description: content });
  };

  const [initiatedDelete, setInitiatedDelete] = useState(false);

  const initiateDelete = () => {
    setInitiatedDelete(true);
  };

  const cancelDelete = () => {
    setInitiatedDelete(false);
  };

  const confirmDelete = () => {
    deleteCategory({ variables: { id: props.category.id } });
    props.onClose();
  };

  return (
    <Modal open={props.open} onClose={props.onClose} className={classes.parent}>
      <Paper className={classes.catmodalStyle}>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            saveCategory();
          }}
        >
          <TextField
            variant="outlined"
            label="Naam"
            name="name"
            required
            defaultValue={state.name}
            className={classes.input}
            onChange={handleTextChange}
          />
          <CategorySelector
            parent={state.parent}
            onChange={handleParentChange}
          />
           <TextField
              required
              variant="outlined"
              label="sorteervolgorde"
              name="order"
              type="number"
              defaultValue={state.order}
              className={[classes.input, classes.lastInput]}
              onChange={handleTextChange}
            />
            <br />
          <Editor
            apiKey={tinyKey}
            initialValue={state.description}
            init={{
              height: 250,
              marginTop: 10,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                // eslint-disable-next-line no-multi-str
                "undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help",
            }}
            onEditorChange={handleDescriptionChange}
          />
           <FormControlLabel
              className={[classes.input, classes.lastInput]}
              control={
                <Switch
                  checked={state.published}
                  onChange={handleCheckChange}
                  name="published"
                  label="Gepubliceerd"
                />
              }
              label="Gepubliceerd"
            />
          <AdminImageDisplay
            images={state.oldImage}
            removeImage={removeImage}
          />
          <ImageUploader
            withIcon={true}
            onChange={onDropImage}
            imgExtension={[".jpg", ".png"]}
            maxFileSize={10024 * 1024 * 1024}
            withPreview={true}
            singleImage={true}
          />
          {!props.category.published && (
              <ConfirmationRequiredButton
                initiated={initiatedDelete}
                cancel={cancelDelete}
                initiate={initiateDelete}
                confirm={confirmDelete}
                confirmationMessage={
                  "Weet je zeker dat je deze categorie wilt verwijderen? Alle gerelateerde informatie wordt ook verwijderd. Dit wordt sterk afgeraden, niet publiceren is bijna altijd een betere optie"
                }
                cancelMessage={"Annuleren"}
                confirmMessage={"Verwijderen"}
                initialMessage={"Verwijderen"}
              />
            )}
          <Button
            variant="contained"
            color="primary"
            className={classes.input}
            type="submit"
          >
            Opslaan
          </Button>
        </form>
      </Paper>
    </Modal>
  );
}
