import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useQuery } from "@apollo/react-hooks";
import { LOGIN_USER } from "../../queries-mutations/adminAuth";
import { setToken } from "../../actions";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = makeStyles((theme) => ({
  paper: {
    padding: 20,
  },
  input: {
    width: 400,
  },
  button: {
    marginTop: 20,
    width: 200,
  },
}));

function Login(props) {
  const classes = styles();

  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const [formData, setFormData] = useState({ email: "", password: "" });

  const { loading, error, data } = useQuery(LOGIN_USER, {
    variables: { email: loginData.email, password: loginData.password },
  });

  data && props.loginAdmin(data.loginUser);

  const login = () => {
    setLoginData({ email: formData.email, password: formData.password });
  };

  const handleTextChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  return (
    <Paper className={classes.paper}>
      <h1>Inloggen</h1>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          login();
        }}
      >
        <TextField
          error={loginData.email !== "" && error}
          id="email"
          name="email"
          label="email"
          className={classes.input}
          onChange={handleTextChange}
        />
        <br />
        <TextField
          error={loginData.password !== "" && error}
          className={classes.input}
          id="password"
          name="password"
          label="Wachtwoord"
          type="password"
          autoComplete="current-password"
          onChange={handleTextChange}
        />
        <br />
        {loading ? (
          <CircularProgress />
        ) : (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Inloggen
          </Button>
        )}
      </form>
    </Paper>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginAdmin: (token) => {
      dispatch(setToken(token));
    },
  };
};

export default connect(null, mapDispatchToProps)(Login);
