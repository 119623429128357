import { combineReducers } from "redux";
import userInformation from "./userInformation";
import cart from "./cart";
import adminAuth from "./adminAuth";
import language from "./language";

export default combineReducers({
  userInformation,
  cart,
  adminAuth,
  language
});
