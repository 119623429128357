import React from "react";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
  addButton: {
    position: "fixed",
    bottom: 20,
    right: 20
  }
}));

export default function AddNewButton(props) {
  const classes = styles();
  return (
    <Fab
      onClick={props.onClick}
      size="medium"
      color="primary"
      aria-label="add"
      className={classes.addButton}
    >
      {props.icon ? props.icon : <AddIcon />}
    </Fab>
  );
}
