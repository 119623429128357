import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Admin from "./Admin/Admin";
import React from "react";
import Users from "./Users/Users";
import Landing from "./Landing/Landing";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../theme";

export default function Routes() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/users">
            <Users />
          </Route>
          <Route path="/admin">
            <Admin />
          </Route>
          <Route path="/code/:urlCode">
            <Landing />
          </Route>
          <Route path="/">
            <Landing />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}
