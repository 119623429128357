import React from "react";
import SingleOrderPickerList from "../helpers/SingleOrderPickerList";
import { Document } from "@react-pdf/renderer";
import { makeStyles } from "@material-ui/core/styles";

export default function DownloadAllOrderPickerLists(props) {

  const styles = makeStyles(theme => ({
    color: {
      color: "red"
    }
  }));
  const classes = styles();
  if (props.showProcessed) {
    console.log(props.data.employees);
    return (
      <Document>
        {props.data.employees.map(orderPickerList =>
          orderPickerList.order[0] &&
          orderPickerList.order[0].processed ? (
            <SingleOrderPickerList
              companyName={props.data.name}
              companyDuiten={props.data.duiten}
              companyEmail={props.data.emailId}
              firstName={orderPickerList.firstName}
              insertion={orderPickerList.insertion}
              lastName={orderPickerList.lastName}
              duiten={props.data.duiten}
              code={orderPickerList.code}
              email={orderPickerList.email}
              orders={orderPickerList.order}
            />
          ) : null
        )}
      </Document>
    );
  } else {
    console.log(props.data.employees);
    return (
      <Document>
        {props.data.employees.map(orderPickerList =>
            orderPickerList.order[0] &&
            !orderPickerList.order[0].processed && (
              <SingleOrderPickerList
              companyName={props.data.name}
              companyDuiten={props.data.duiten}
              companyEmail={props.data.emailId}
              firstName={orderPickerList.firstName}
              insertion={orderPickerList.insertion}
              lastName={orderPickerList.lastName}
              duiten={props.data.duiten}
              code={orderPickerList.code}
              email={orderPickerList.email}
              orders={orderPickerList.order}
              />
            )
        )}
      </Document>
    );
  }
}
