import React from "react";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";

export default function VragenTable(props) {
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
      },
    },
    {
      name: "name",
      label: "Naam",
    },
    {
      name: "details",
      label: "Details",
    },
    {
      name: "code",
      label: "Code",
    },
    {
      name: "employer",
      label: "Werkgever",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "phone",
      label: "Telefoon",
    },
    {
      name: "done",
      label: "Verwerkt",
      options: { display: false },
    },
    { name: "markDone", label: "Markeer als klaar" },
  ];

  let tableData = [];

  props.data.unfinishedContactForms.forEach(function (form) {
    tableData.push({
      name: form.name,
      phone: form.phone,
      email: form.email,
      details: form.details,
      code: form.code,
      done: form.done,
      employer: form.employer,
      markDone: form.done ? (
        <Typography>Verwerkt</Typography>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            props.finish(form.id);
          }}
        >
          Verwerk
        </Button>
      ),
    });
  });

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  return (
    <MUIDataTable
      title={"Producten"}
      data={tableData}
      columns={columns}
      options={options}
    />
  );
}
