import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CategoriesTable from "../../components/CategoriesTable";
import CategoriesNotPublishedTable from "../../components/CategoriesNotPublishedTable";
import EditCategoryModal from "../../components/EditCategoryModal";
import AddNewButton from "../../components/AddNewButton";

export default function Categorieen() {
  const [modal, setModal] = React.useState();

  const handleClose = () => {
    setModal(<div />);
   // window.location.reload();
  };

  const onRowClick = clickedCategory => {
    setModal(
      <EditCategoryModal
        open={true}
        onClose={handleClose}
        category={clickedCategory}
      />
    );
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <h1>Categorieën</h1>
        </Paper>
        <CategoriesTable onRowClick={onRowClick} />
        <br/>
        <CategoriesNotPublishedTable onRowClick={onRowClick} />
        <AddNewButton onClick={onRowClick} />
        {modal}
      </Grid>
    </Grid>
  );
}
