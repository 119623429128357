import React, { useState } from "react";
import VragenTable from "../../components/VragenTable";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  FINISH_CONTACT_FORM,
  UNFINISHED_CONTACT_FORMS,
} from "../../queries-mutations/adminAuth";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  paper: {
    marginTop: 25,
    padding: 10,
  },
}));

export default function Vragen() {
  const classes = styles();
  const [onlyUnfinished, setOnlyUnfinished] = useState(true);

  const { loading, error, data, refetch } = useQuery(UNFINISHED_CONTACT_FORMS, {
    variables: { onlyUnfinished: onlyUnfinished },
    partialRefetch: true,
  });

  const [finishContactForm] = useMutation(FINISH_CONTACT_FORM);

  const finish = (id) => {
    finishContactForm({ variables: { id: id } }).then((r) => {
      refetch();
    });
  };

  const handleCheck = () => {
    setOnlyUnfinished(!onlyUnfinished);
  };

  return (
    <div>
      {data && <VragenTable data={data} finish={finish} />}
      {error && (
        <Paper>
          <p>Er ging iets mis, probeer het later weer</p>
        </Paper>
      )}
      {loading && <CircularProgress />}
      <Paper className={classes.paper}>
        <FormControlLabel
          control={
            <Checkbox
              checked={onlyUnfinished}
              onChange={handleCheck}
              checkedIcon={<VisibilityOffIcon />}
              icon={<VisibilityIcon />}
              name="checkedH"
            />
          }
          label={
            onlyUnfinished
              ? "Verwerkte vragen verborgen"
              : "Verwerkte vragen zichtbaar"
          }
        />
      </Paper>
    </div>
  );
}
