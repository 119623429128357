import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { ADMIN_OVERVIEW_PRODUCTS } from "../queries-mutations/products";
import { Multiselect } from "multiselect-react-dropdown";

export default function ProductSelector(props) {
  const { loading, error, data } = useQuery(ADMIN_OVERVIEW_PRODUCTS, {
    returnPartialData: false , errorPolicy: "ignore" 
  },{ errorPolicy: "all" });
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error! ${error.message}</div>;
 
  var productList = [];
  productList =  data.products.map(prod => {
    if(prod.categories.length > 0){
    prod['category'] = prod.categories[0].id;
    }
    else{
      prod['category'] = '';
    }
    return prod;
  });
  productList = productList.filter( prod => {
    if(prod.category == props.selectedCategory){
      return prod;
    }
  });
  productList.forEach(options=>{
    options.displayValue=options.name+" - €"+options.price;
 });

  return (
    <Multiselect
    placeholder="Selecteer"
    options={productList.filter(product => {
      return product.published;
    })} // Options to display in the dropdown
    selectedValues={props.selectedProducts} // Preselected value to persist in dropdown
    onSelect={props.onSelectProduct} // Function will trigger on select event
    onRemove={props.onRemoveProduct} // Function will trigger on remove event
    displayValue="displayValue" // Property name to display in the dropdown options
  />
  );
}