import React from "react";
import Grid from "@material-ui/core/Grid";
import { TableContainer, Typography } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
  container: {
    marginBottom: 25
  },
  leftAlign: {
    textAlign: "left"
  },
  cell: {
    width: "33%",
    textAlign: "left"
  }
}));

export default function SingleOrderInfoAdmin(props) {
  const classes = styles();

  const d = Date.parse(props.data.createdAt);

  const ye = new Intl.DateTimeFormat("nl", { year: "numeric" }).format(d);
  const mo = new Intl.DateTimeFormat("nl", { month: "short" }).format(d);
  const da = new Intl.DateTimeFormat("nl", { day: "2-digit" }).format(d);
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography className={classes.leftAlign}>
          Bestelling geplaatst op: {da} {mo} {ye}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.cell}>Naam</TableCell>
                <TableCell className={classes.cell}>Code</TableCell>
                <TableCell className={classes.cell}>Besteld voor</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.products.map(
                product =>
                  product.hasProduct && (
                    <TableRow>
                      <TableCell className={classes.cell}>
                        {product.hasProduct.name}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {product.hasProduct.code}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {product.price}
                      </TableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
