import React from "react";
import Grid from "@material-ui/core/Grid";
import SingleProductOrderOverview from "./SingleProductOrderOverview";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
  paper: {
    marginTop: 20,
    marginBottom: 20,
    paddingBottom: 10,
    paddingTop: 10
  }
}));

export default function SingleOrderOverview(props) {
  const classes = styles();

  const d = Date.parse(props.data.createdAt);
  console.log("test",props.data.products)
  props.data.products.map(product => { console.log(product?.hasProduct?.name )})

  const ye = new Intl.DateTimeFormat("nl", { year: "numeric" }).format(d);
  const mo = new Intl.DateTimeFormat("nl", { month: "short" }).format(d);
  const da = new Intl.DateTimeFormat("nl", { day: "2-digit" }).format(d);

  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid xs={3} item>
          {da} {mo} {ye}
        </Grid>
        <Grid item xs={9}>
          {props.data.products.map(product => (
           
            // <SingleProductOrderOverview
            //   name={product.hasProduct.name}
            //   price={product.price}
            //   newImg={
            //     product.hasProduct.images[0]
            //       ? product.hasProduct.images[0].new
            //       : false
            //   }
            //   img={
            //     product.hasProduct.images[0]
            //       ? product.hasProduct.images[0].location
            //       : null
            //   }
            // />

            <SingleProductOrderOverview
            key={product.hasProduct ? product.hasProduct.id : product.price} 
            name={product.hasProduct ? product.hasProduct.name : "Unknown"}
            price={product.price}
            newImg={
              product.hasProduct && product.hasProduct.images && product.hasProduct.images[0]
                ? product.hasProduct.images[0].new
                : false
            }            
            img={product.hasProduct?.images?.[0]?.location || null}

          />
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
}
