import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import { editModalStyle } from "../styles/editModalStyle";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_COMPANY, UPDATE_COMPANY } from "../queries-mutations/companies";

export default function NewCompanyModal(props) {
  const classes = editModalStyle();

  console.log(props);

  const [bedrijf, setBedrijf] = useState({
    name: props.edit ? props.company.name : "",
    code: props.edit ? props.company.code : "",
    duiten: props.edit ? props.company.duiten : "",
    endDate: props.edit ? props.company.endDate : new Date(),
    emailId: props.edit ? props.company.emailId : "",
    reminderEmailId: ""
  });
  const [createBedrijf] = useMutation(CREATE_COMPANY);
  const [updateBedrijf] = useMutation(UPDATE_COMPANY);

  const saveCompany = () => {
    props.edit
      ? updateBedrijf({
          variables: {
            id: props.company.id,
            name: bedrijf.name,
            code: bedrijf.code,
            duiten: parseInt(bedrijf.duiten),
            endDate: bedrijf.endDate,
            emailId: bedrijf.emailId,
            reminderEmailId: ""
          }
        })
      : createBedrijf({
          variables: {
            name: bedrijf.name,
            code: bedrijf.code,
            duiten: parseInt(bedrijf.duiten),
            endDate: bedrijf.endDate,
            emailId: bedrijf.emailId,
            reminderEmailId: ""
          }
        });
    props.onClose();
  };

  const handleTextChange = event => {
    setBedrijf({ ...bedrijf, [event.target.name]: event.target.value });
  };
  return (
    <Modal open={props.open} onClose={props.onClose} className={classes.parent}>
      <Paper className={classes.smallModalStyle}>
        <h1>Nieuw bedrijf</h1>
        <form
          onSubmit={event => {
            event.preventDefault();
            saveCompany();
          }}
        >
          <TextField
            variant="outlined"
            label="Naam"
            name="name"
            required
            defaultValue={bedrijf.name}
            className={classes.input}
            onChange={handleTextChange}
          />
          <br />
          <TextField
            variant="outlined"
            label="Code"
            name="code"
            required
            disabled={props.edit}
            defaultValue={bedrijf.code}
            className={classes.input}
            onChange={handleTextChange}
          />
          <br />
          <TextField
            variant="outlined"
            id="emailId"
            name="emailId"
            label="Email ID"
            defaultValue={bedrijf.emailId}
            className={classes.input}
            onChange={handleTextChange}
          />
          <br />
          <TextField
            id="duiten"
            name="duiten"
            label="Standaard aantal duiten"
            type="number"
            required
            defaultValue={bedrijf.duiten}
            onChange={handleTextChange}
            className={classes.input}
          />
          <br />
          <TextField
            id="endDate"
            name="endDate"
            label="Einddatum"
            type="date"
            required
            defaultValue={bedrijf.endDate}
            onChange={handleTextChange}
            className={classes.input}
            InputLabelProps={{
              shrink: true
            }}
          />
          <br />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.input}
            size="large"
          >
            Opslaan
          </Button>
        </form>
      </Paper>
    </Modal>
  );
}
