import React from "react";
import MUIDataTable from "mui-datatables";
import moment from "moment";

export default function OrdersByDateTable(props) {
  console.log(props);
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false
      }
    },
    {
      name: "name",
      label: "Naam"
    },
    {
      name: "code",
      label: "Code"
    },
    {
      name: "orders",
      label: "Aantal besteld"
    },
    {
      name: "stockAmount",
      label: "Voorraad"
    }
  ];

  let tableData = [];

  props.data.ordersByDate.map(shopped => {
    shopped.products.map(t_product => {
      let product = t_product.hasProduct;
      if (tableData.some(el => el.id === product.id)) {
        let index = tableData.findIndex(item => item.id === product.id);
        tableData[index].orders += 1;
      } else {
        product.orders = 1;
        tableData.push(product);
      }
    });
  });

  const onRowClick = selected => {
    const selectedProduct = props.data.products.find(
      product => product.id === selected[0]
    );
    props.onRowClick(selectedProduct);
  };

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    onRowClick: onRowClick
  };

  return (
    <MUIDataTable
      title={
        "Bestelde producten op " + moment(props.date).format("DD MMMM YYYY")
      }
      data={tableData}
      columns={columns}
      options={options}
    />
  );
}
