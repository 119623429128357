import React from "react";
import Popover from "@material-ui/core/Popover";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Translate } from "react-auto-translate";

const styles = makeStyles(theme => ({ popover: { margin: 25 } }));

export default function LoginCodePopup() {
  const classes = styles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <span onClick={handleClick}>
        <Translate>Inlogcode kwijt?</Translate>
      </span>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Typography className={classes.popover}>
          <Translate>
            Ben je je inlogcode kwijt? Neem dan contact op met je werkgever.
          </Translate>
        </Typography>
      </Popover>
    </div>
  );
}
