import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import React, { useState } from "react";
import { editModalStyle } from "../styles/editModalStyle";
import { CSVReader } from "react-papaparse";
import Button from "@material-ui/core/Button";
import CodeGenerator from "../helpers/CodeGenerator";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { COMPANY_CODES } from "../queries-mutations/companies";
import { CREATE_CUSTOMER } from "../queries-mutations/customers";
import { trackPromise } from "react-promise-tracker";

export default function MultiNewCustomerModal(props) {
  const [customerData, setCustomerData] = useState([]);
  const classes = editModalStyle();

  const handleOnDrop = data => {
    console.log(data);
    setCustomerData(data);
  };

  const { loading, error, data } = useQuery(COMPANY_CODES, {
    variables: { id: props.companyId }
  });

  const [createCustomer] = useMutation(CREATE_CUSTOMER);

  const import_users = () => {
    let codes = [];
    data.company.employees.map(employee => {
      codes.push(employee.code);
    });
    customerData.map((customer,index) => {
      setTimeout(()=> { 
        console.log(customer.data[3]);
        //console.log(customer);
      let generatedCode = props.companyCode + "-" + CodeGenerator();
      while (codes.includes(generatedCode)) {
        generatedCode = props.companyCode + "-" + CodeGenerator();
      }
      codes.push(generatedCode);
      if (customer.data[3] !== "email" && customer.data[3]) {
        trackPromise(
          createCustomer({
            variables: {
              code: generatedCode,
              firstName: customer.data[0],
              insertion: customer.data[1],
              lastName: customer.data[2],
              email: customer.data[3],
              gender: customer.data[4],
              duiten: parseInt(props.duiten),
              company: props.companyId
            }
          })
        )
      }
      props.onClose();
    }, 1500*index);
  });
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  return (
    <Modal open={props.open} onClose={props.onClose} className={classes.parent}>
      <Paper className={classes.smallModalStyle}>
        <h1>Gebruikers importeren vanuit csv</h1>
        <span>
          <p>
            Sleep een CSV bestand of klik hier om een CSV bestand toe te voegen.
          </p>
          <p>
            Dit bestand moet de kolommen 'voornaam', 'tussenvoegsel',
            'achternaam' en 'email' hebben.
          </p>
        </span>
        <CSVReader onDrop={handleOnDrop} onError={handleOnError} fileEncoding="UTF-8">
          <span>
            Sleep een CSV bestand of klik hier om een CSV bestand toe te voegen.
          </span>
          <p>
            Dit bestand moet de kolommen 'voornaam', 'tussenvoegsel',
            'achternaam' en 'email' hebben.
          </p>
        </CSVReader>
        <Button
          variant="contained"
          color="secondary"
          className={classes.input}
          disabled={
            !(
              data &&
              customerData[0] &&
              customerData[0].data &&
              customerData[0].data[0] === "voornaam" &&
              customerData[0].data[1] === "tussenvoegsel" &&
              customerData[0].data[2] === "achternaam" &&
              customerData[0].data[3] === "email"
            )
          }
          onClick={import_users}
        >
          Importeren
        </Button>
      </Paper>
    </Modal>
  );
}
