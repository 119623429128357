import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function DuitenIcon(props) {
  return (
    <SvgIcon className={props.className} viewBox="0 0 468.293 468.293">
      <path
        fill="#F6C358"
        d="M396.457,315.835l0,53.158c0,11.65-8.581,22.603-23.789,32.23c-2.163,1.326-4.465,2.721-6.976,3.976
	c0,0-0.07,0-0.07,0.07c-2.163,1.186-4.465,2.303-6.906,3.488c-8.162,3.837-17.58,7.395-27.905,10.534
	c-2.302,0.698-4.605,1.395-6.976,2.093h-0.07c-2.232,0.628-4.535,1.255-6.906,1.884c-8.79,2.232-18.069,4.255-27.905,5.93
	c-2.302,0.418-4.605,0.837-6.976,1.186h-0.07c-2.302,0.348-4.535,0.766-6.906,1.046c-8.999,1.325-18.278,2.372-27.905,3.209
	c-2.302,0.209-4.605,0.419-6.976,0.559h-0.07c-2.302,0.209-4.535,0.349-6.906,0.489c-9.069,0.557-18.417,0.906-27.905,0.976
	c-2.302,0.07-4.605,0.07-6.976,0.07h-0.07c-2.372,0-4.604,0-6.906-0.07c-9.488-0.07-18.836-0.419-27.905-0.976
	c-2.372-0.14-4.674-0.279-6.976-0.489h-0.07c-2.372-0.14-4.604-0.349-6.906-0.559c-9.627-0.836-18.906-1.884-27.905-3.209
	c-2.372-0.279-4.674-0.697-6.976-1.046h-0.07c-2.372-0.349-4.604-0.768-6.906-1.186c-9.837-1.675-19.115-3.698-27.905-5.93
	c-2.372-0.628-4.744-1.256-6.976-1.884h-0.07c-2.372-0.698-4.604-1.395-6.906-2.093c-10.325-3.139-19.743-6.697-27.905-10.534
	c-2.442-1.116-4.744-2.302-6.976-3.558c0,0-0.07,0-0.07-0.07c-2.441-1.185-4.744-2.51-6.906-3.906C8.65,391.666,0,380.643,0,368.994
	l0-53.158L396.457,315.835z"
      />
      <path
        fill="#FCD462"
        d="M396.457,315.835c0,11.651-8.581,22.603-23.789,32.09c-2.232,1.465-4.535,2.86-7.046,4.117
	c-2.163,1.255-4.465,2.371-6.906,3.557c-8.162,3.837-17.58,7.325-27.905,10.534c-2.302,0.697-4.605,1.396-6.976,2.092h-0.07
	c-2.232,0.628-4.535,1.257-6.906,1.884c-8.79,2.233-18.069,4.256-27.905,5.93c-2.302,0.419-4.605,0.837-6.976,1.185h-0.07
	c-2.302,0.349-4.535,0.768-6.906,1.047c-8.999,1.326-18.278,2.372-27.905,3.21c-2.302,0.209-4.605,0.418-6.976,0.558h-0.07
	c-2.302,0.209-4.535,0.348-6.906,0.488c-9.069,0.559-18.417,0.908-27.905,0.977c-2.302,0.07-4.605,0.07-6.976,0.07h-0.07
	c-2.372,0-4.604,0-6.906-0.07c-9.488-0.069-18.836-0.418-27.905-0.977c-2.372-0.14-4.674-0.279-6.976-0.488h-0.07
	c-2.372-0.14-4.604-0.348-6.906-0.558c-9.627-0.838-18.906-1.884-27.905-3.21c-2.372-0.278-4.674-0.697-6.976-1.047h-0.07
	c-2.372-0.348-4.604-0.766-6.906-1.185c-9.837-1.674-19.115-3.698-27.905-5.93c-2.372-0.627-4.744-1.255-6.976-1.884h-0.07
	c-2.372-0.697-4.604-1.395-6.906-2.092c-10.325-3.14-19.743-6.697-27.905-10.534c-2.442-1.116-4.744-2.302-6.976-3.557
	c0,0-0.07,0-0.07-0.07c-2.441-1.187-4.744-2.512-6.906-3.907C8.65,338.508,0,327.486,0,315.835
	c0-37.392,88.807-67.739,198.263-67.739C307.72,248.096,396.457,278.443,396.457,315.835z"
      />
      <path
        fill="#F6C358"
        d="M197.642,364.646c-1.969,0-3.845-0.003-5.774-0.062c-9.181-0.074-18.224-0.391-27.325-0.949
	c-2.221-0.132-4.323-0.259-6.421-0.45l-0.856-0.077h-0.351c-1.793-0.114-3.506-0.274-5.256-0.431
	c-10.287-0.9-19.049-1.907-27.377-3.13c-1.879-0.231-3.195-0.45-4.504-0.659l-3.848-0.592c-1.393-0.225-4.973-0.881-4.973-0.881
	c-8.999-1.531-17.641-3.367-26.628-5.653c-2.104-0.555-4.372-1.155-6.504-1.756l-6.636-1.99c-7.832-2.382-15.047-5.007-21.496-7.813
	v-0.077l-10.555-5.13l-3.346-1.882h-0.136c-0.508-0.302-1.017-0.619-1.522-0.946c-11.276-7.086-15.204-13.205-15.204-16.332
	c0-17.207,68.085-48.811,179.334-48.811c111.203,0,179.263,31.604,179.263,48.811c0,3.112-3.897,9.178-14.878,16.033
	c-2.326,1.516-3.962,2.477-5.499,3.247l-1.023,0.551c-1.756,1.02-3.639,1.913-5.62,2.878c-6.806,3.198-15.377,6.424-25.316,9.514
	l-6.553,1.947c-2.138,0.601-4.338,1.202-6.605,1.799c-8.824,2.243-17.466,4.079-26.253,5.573c-1.932,0.351-3.688,0.672-5.447,0.949
	l-3.802,0.585c-1.235,0.203-2.468,0.41-3.753,0.561c-8.867,1.3-17.632,2.305-27.325,3.149c-2.012,0.185-3.941,0.36-5.912,0.487
	l-1.208,0.077c-2.098,0.191-4.135,0.317-6.294,0.444c-9.157,0.564-18.199,0.881-26.943,0.946c-2.357,0.068-4.338,0.071-6.341,0.071
	C198.594,364.646,197.824,364.646,197.642,364.646z"
      />
      <g>
        <path
          fill="#DC8744"
          d="M37.811,355.599l0,53.158c-2.442-1.116-4.744-2.302-6.976-3.558c0,0-0.07,0-0.07-0.07
		c-2.441-1.185-4.744-2.51-6.906-3.906l0-53.158c2.163,1.395,4.465,2.72,6.906,3.907c0,0.07,0.07,0.07,0.07,0.07
		C33.067,353.297,35.369,354.483,37.811,355.599z"
        />
        <path
          fill="#DC8744"
          d="M79.668,370.109l0,53.159c-2.372-0.628-4.744-1.256-6.976-1.884h-0.07
		c-2.372-0.698-4.604-1.395-6.906-2.093l0-53.158c2.302,0.697,4.534,1.396,6.906,2.092h0.07
		C74.924,368.854,77.296,369.483,79.668,370.109z"
        />
        <path
          fill="#DC8744"
          d="M121.525,378.272l0,53.158c-2.372-0.279-4.674-0.697-6.976-1.046h-0.07
		c-2.372-0.349-4.604-0.768-6.906-1.186l0-53.158c2.302,0.419,4.534,0.837,6.906,1.185h0.07
		C116.851,377.574,119.153,377.993,121.525,378.272z"
        />
        <path
          fill="#DC8744"
          d="M163.382,382.527l0,53.159c-2.372-0.14-4.674-0.279-6.976-0.489h-0.07
		c-2.372-0.14-4.604-0.349-6.906-0.559l0-53.158c2.302,0.209,4.534,0.418,6.906,0.558h0.07
		C158.708,382.248,161.01,382.387,163.382,382.527z"
        />
        <path
          fill="#DC8744"
          d="M205.239,383.504l0,53.158c-2.302,0.07-4.605,0.07-6.976,0.07h-0.07c-2.372,0-4.604,0-6.906-0.07
		l0-53.158c2.302,0.07,4.534,0.07,6.906,0.07h0.07C200.635,383.574,202.937,383.574,205.239,383.504z"
        />
        <path
          fill="#DC8744"
          d="M247.096,381.481l0,53.158c-2.302,0.209-4.605,0.419-6.976,0.559h-0.07
		c-2.302,0.209-4.535,0.349-6.906,0.489l0-53.159c2.372-0.14,4.604-0.279,6.906-0.488h0.07
		C242.492,381.9,244.794,381.691,247.096,381.481z"
        />
        <path
          fill="#DC8744"
          d="M288.954,376.04l0,53.158c-2.302,0.418-4.605,0.837-6.976,1.186h-0.07
		c-2.302,0.348-4.535,0.766-6.906,1.046l0-53.158c2.372-0.278,4.604-0.697,6.906-1.047h0.07
		C284.349,376.877,286.651,376.459,288.954,376.04z"
        />
        <path
          fill="#DC8744"
          d="M330.811,366.133l0,53.158c-2.302,0.698-4.605,1.395-6.976,2.093h-0.07
		c-2.232,0.628-4.535,1.255-6.906,1.884l0-53.159c2.372-0.627,4.674-1.255,6.906-1.884h0.07
		C326.206,367.529,328.508,366.831,330.811,366.133z"
        />
        <path
          fill="#DC8744"
          d="M372.668,347.926l0,53.298c-2.163,1.326-4.465,2.721-6.976,3.976c0,0-0.07,0-0.07,0.07
		c-2.163,1.186-4.465,2.303-6.906,3.488l0-53.158c2.441-1.186,4.744-2.302,6.906-3.557
		C368.133,350.785,370.436,349.39,372.668,347.926z"
        />
      </g>
      <path
        fill="#F6C358"
        d="M468.293,207.568l0,53.158c0,11.65-8.581,22.603-23.789,32.23c-2.163,1.326-4.465,2.721-6.976,3.976
	c0,0-0.07,0-0.07,0.07c-2.163,1.186-4.465,2.303-6.906,3.488c-8.162,3.837-17.58,7.395-27.905,10.534
	c-2.302,0.698-4.605,1.395-6.976,2.093h-0.07c-2.232,0.628-4.535,1.255-6.906,1.884c-8.79,2.232-18.069,4.255-27.905,5.93
	c-2.302,0.418-4.605,0.837-6.976,1.186h-0.07c-2.302,0.348-4.535,0.766-6.906,1.046c-8.999,1.325-18.278,2.372-27.905,3.209
	c-2.302,0.21-4.605,0.419-6.976,0.559h-0.07c-2.302,0.209-4.535,0.349-6.906,0.489c-9.069,0.557-18.417,0.906-27.905,0.976
	c-2.302,0.07-4.605,0.07-6.976,0.07h-0.07c-2.372,0-4.604,0-6.906-0.07c-9.488-0.07-18.836-0.419-27.905-0.976
	c-2.372-0.14-4.674-0.279-6.976-0.489h-0.07c-2.372-0.14-4.604-0.349-6.906-0.559c-9.627-0.836-18.906-1.884-27.905-3.209
	c-2.372-0.279-4.674-0.697-6.976-1.046h-0.07c-2.372-0.349-4.604-0.768-6.906-1.186c-9.837-1.675-19.115-3.698-27.905-5.93
	c-2.372-0.628-4.744-1.256-6.976-1.884h-0.07c-2.372-0.698-4.604-1.395-6.906-2.093c-10.325-3.139-19.743-6.697-27.905-10.534
	c-2.442-1.116-4.744-2.302-6.976-3.558c0,0-0.07,0-0.07-0.07c-2.441-1.185-4.744-2.51-6.906-3.906
	c-15.208-9.557-23.858-20.58-23.858-32.23l0-53.158L468.293,207.568z"
      />
      <path
        fill="#FCD462"
        d="M468.293,207.568c0,11.651-8.581,22.603-23.789,32.09c-2.232,1.465-4.535,2.86-7.046,4.117
	c-2.163,1.255-4.465,2.371-6.906,3.557c-8.162,3.837-17.58,7.325-27.905,10.534c-2.302,0.697-4.605,1.396-6.976,2.092h-0.07
	c-2.232,0.628-4.535,1.257-6.906,1.884c-8.79,2.233-18.069,4.256-27.905,5.93c-2.302,0.419-4.605,0.837-6.976,1.185h-0.07
	c-2.302,0.349-4.535,0.768-6.906,1.047c-8.999,1.326-18.278,2.372-27.905,3.21c-2.302,0.21-4.605,0.418-6.976,0.558h-0.07
	c-2.302,0.209-4.535,0.348-6.906,0.488c-9.069,0.559-18.417,0.908-27.905,0.977c-2.302,0.07-4.605,0.07-6.976,0.07h-0.07
	c-2.372,0-4.604,0-6.906-0.07c-9.488-0.069-18.836-0.418-27.905-0.977c-2.372-0.14-4.674-0.279-6.976-0.488h-0.07
	c-2.372-0.14-4.604-0.348-6.906-0.558c-9.627-0.838-18.906-1.884-27.905-3.21c-2.372-0.278-4.674-0.697-6.976-1.047h-0.07
	c-2.372-0.348-4.604-0.766-6.906-1.185c-9.837-1.674-19.115-3.698-27.905-5.93c-2.372-0.627-4.744-1.255-6.976-1.884h-0.07
	c-2.372-0.697-4.604-1.395-6.906-2.092c-10.325-3.14-19.743-6.697-27.905-10.534c-2.442-1.116-4.744-2.302-6.976-3.557
	c0,0-0.07,0-0.07-0.07c-2.441-1.187-4.744-2.512-6.906-3.907c-15.208-9.557-23.858-20.579-23.858-32.23
	c0-37.392,88.807-67.739,198.263-67.739S468.293,170.175,468.293,207.568z"
      />
      <path
        fill="#F6C358"
        d="M269.478,256.378c-1.969,0-3.845-0.003-5.774-0.062c-9.181-0.074-18.224-0.391-27.325-0.949
	c-2.221-0.132-4.323-0.259-6.421-0.45l-0.856-0.077h-0.351c-1.793-0.114-3.506-0.274-5.256-0.431
	c-10.287-0.9-19.049-1.907-27.377-3.13c-1.879-0.231-3.195-0.45-4.504-0.659l-3.848-0.592c-1.393-0.225-4.973-0.881-4.973-0.881
	c-8.999-1.531-17.641-3.367-26.628-5.653c-2.104-0.555-4.372-1.155-6.504-1.756l-6.636-1.99c-7.832-2.382-15.047-5.007-21.496-7.813
	v-0.077l-10.555-5.13l-3.346-1.882h-0.136c-0.508-0.302-1.017-0.619-1.522-0.946c-11.276-7.086-15.204-13.205-15.204-16.332
	c0-17.207,68.085-48.811,179.334-48.811c111.203,0,179.263,31.604,179.263,48.811c0,3.112-3.897,9.178-14.878,16.033
	c-2.326,1.516-3.962,2.477-5.499,3.247l-1.023,0.551c-1.756,1.02-3.639,1.913-5.62,2.878c-6.806,3.198-15.377,6.424-25.316,9.514
	l-6.553,1.947c-2.138,0.601-4.338,1.202-6.605,1.799c-8.824,2.243-17.466,4.079-26.253,5.573c-1.932,0.351-3.688,0.672-5.447,0.949
	l-3.802,0.585c-1.235,0.203-2.468,0.41-3.753,0.561c-8.867,1.3-17.632,2.305-27.325,3.149c-2.012,0.185-3.941,0.36-5.912,0.487
	l-1.208,0.077c-2.098,0.191-4.135,0.317-6.294,0.444c-9.157,0.564-18.199,0.881-26.943,0.946c-2.357,0.068-4.338,0.071-6.341,0.071
	C270.43,256.378,269.66,256.378,269.478,256.378z"
      />
      <g>
        <path
          fill="#DC8744"
          d="M109.647,247.331l0,53.158c-2.442-1.116-4.744-2.302-6.976-3.558c0,0-0.07,0-0.07-0.07
		c-2.441-1.185-4.744-2.51-6.906-3.906l0-53.158c2.163,1.395,4.465,2.72,6.906,3.907c0,0.07,0.07,0.07,0.07,0.07
		C104.903,245.029,107.205,246.215,109.647,247.331z"
        />
        <path
          fill="#DC8744"
          d="M151.504,261.841l0,53.159c-2.372-0.628-4.744-1.256-6.976-1.884h-0.07
		c-2.372-0.698-4.604-1.395-6.906-2.093l0-53.158c2.302,0.697,4.534,1.396,6.906,2.092h0.07
		C146.76,260.586,149.132,261.215,151.504,261.841z"
        />
        <path
          fill="#DC8744"
          d="M193.361,270.004l0,53.158c-2.372-0.279-4.674-0.697-6.976-1.046h-0.07
		c-2.372-0.349-4.604-0.768-6.906-1.186l0-53.158c2.302,0.419,4.534,0.837,6.906,1.185h0.07
		C188.687,269.306,190.989,269.725,193.361,270.004z"
        />
        <path
          fill="#DC8744"
          d="M235.218,274.259l0,53.159c-2.372-0.14-4.674-0.279-6.976-0.489h-0.07
		c-2.372-0.14-4.604-0.349-6.906-0.559l0-53.158c2.302,0.21,4.534,0.418,6.906,0.558h0.07
		C230.544,273.98,232.846,274.12,235.218,274.259z"
        />
        <path
          fill="#DC8744"
          d="M277.075,275.236l0,53.158c-2.302,0.07-4.605,0.07-6.976,0.07h-0.07c-2.372,0-4.604,0-6.906-0.07
		l0-53.158c2.302,0.07,4.534,0.07,6.906,0.07h0.07C272.471,275.306,274.773,275.306,277.075,275.236z"
        />
        <path
          fill="#DC8744"
          d="M318.932,273.214l0,53.158c-2.302,0.21-4.605,0.419-6.976,0.559h-0.07
		c-2.302,0.209-4.535,0.349-6.906,0.489l0-53.159c2.372-0.14,4.604-0.279,6.906-0.488h0.07
		C314.328,273.632,316.63,273.423,318.932,273.214z"
        />
        <path
          fill="#DC8744"
          d="M360.79,267.772l0,53.158c-2.302,0.418-4.605,0.837-6.976,1.186h-0.07
		c-2.302,0.348-4.535,0.766-6.906,1.046l0-53.158c2.372-0.278,4.604-0.697,6.906-1.047h0.07
		C356.185,268.609,358.487,268.191,360.79,267.772z"
        />
        <path
          fill="#DC8744"
          d="M402.647,257.866l0,53.158c-2.302,0.698-4.605,1.395-6.976,2.093h-0.07
		c-2.232,0.628-4.535,1.255-6.906,1.884l0-53.159c2.372-0.627,4.674-1.255,6.906-1.884h0.07
		C398.042,259.261,400.344,258.563,402.647,257.866z"
        />
        <path
          fill="#DC8744"
          d="M444.504,239.658l0,53.298c-2.163,1.326-4.465,2.721-6.976,3.976c0,0-0.07,0-0.07,0.07
		c-2.163,1.186-4.465,2.303-6.906,3.488l0-53.158c2.442-1.186,4.744-2.302,6.906-3.557
		C439.969,242.517,442.272,241.123,444.504,239.658z"
        />
      </g>
      <path
        fill="#F6C358"
        d="M396.457,99.3l0,53.158c0,11.65-8.581,22.603-23.789,32.23c-2.163,1.326-4.465,2.721-6.976,3.976
	c0,0-0.07,0-0.07,0.07c-2.163,1.186-4.465,2.303-6.906,3.488c-8.162,3.837-17.58,7.395-27.905,10.534
	c-2.302,0.698-4.605,1.395-6.976,2.093h-0.07c-2.232,0.628-4.535,1.255-6.906,1.884c-8.79,2.232-18.069,4.255-27.905,5.93
	c-2.302,0.418-4.605,0.837-6.976,1.186h-0.07c-2.302,0.348-4.535,0.766-6.906,1.046c-8.999,1.325-18.278,2.373-27.905,3.209
	c-2.302,0.209-4.605,0.419-6.976,0.559h-0.07c-2.302,0.21-4.535,0.349-6.906,0.489c-9.069,0.557-18.417,0.906-27.905,0.976
	c-2.302,0.07-4.605,0.07-6.976,0.07h-0.07c-2.372,0-4.604,0-6.906-0.07c-9.488-0.07-18.836-0.419-27.905-0.976
	c-2.372-0.14-4.674-0.279-6.976-0.489h-0.07c-2.372-0.14-4.604-0.349-6.906-0.559c-9.627-0.836-18.906-1.884-27.905-3.209
	c-2.372-0.279-4.674-0.697-6.976-1.046h-0.07c-2.372-0.349-4.604-0.768-6.906-1.186c-9.837-1.675-19.115-3.698-27.905-5.93
	c-2.372-0.628-4.744-1.256-6.976-1.884h-0.07c-2.372-0.698-4.604-1.395-6.906-2.093c-10.325-3.139-19.743-6.697-27.905-10.534
	c-2.442-1.116-4.744-2.302-6.976-3.558c0,0-0.07,0-0.07-0.07c-2.441-1.185-4.744-2.51-6.906-3.906C8.65,175.131,0,164.108,0,152.458
	L0,99.3L396.457,99.3z"
      />
      <path
        fill="#FCD462"
        d="M396.457,99.3c0,11.651-8.581,22.603-23.789,32.09c-2.232,1.465-4.535,2.86-7.046,4.117
	c-2.163,1.255-4.465,2.371-6.906,3.557c-8.162,3.837-17.58,7.325-27.905,10.534c-2.302,0.697-4.605,1.396-6.976,2.092h-0.07
	c-2.232,0.628-4.535,1.257-6.906,1.884c-8.79,2.233-18.069,4.256-27.905,5.93c-2.302,0.419-4.605,0.837-6.976,1.185h-0.07
	c-2.302,0.349-4.535,0.768-6.906,1.047c-8.999,1.326-18.278,2.372-27.905,3.21c-2.302,0.209-4.605,0.418-6.976,0.558h-0.07
	c-2.302,0.209-4.535,0.348-6.906,0.488c-9.069,0.559-18.417,0.908-27.905,0.977c-2.302,0.07-4.605,0.07-6.976,0.07h-0.07
	c-2.372,0-4.604,0-6.906-0.07c-9.488-0.069-18.836-0.418-27.905-0.977c-2.372-0.14-4.674-0.279-6.976-0.488h-0.07
	c-2.372-0.14-4.604-0.348-6.906-0.558c-9.627-0.838-18.906-1.884-27.905-3.21c-2.372-0.278-4.674-0.697-6.976-1.047h-0.07
	c-2.372-0.348-4.604-0.766-6.906-1.185c-9.837-1.674-19.115-3.698-27.905-5.93c-2.372-0.627-4.744-1.255-6.976-1.884h-0.07
	c-2.372-0.697-4.604-1.395-6.906-2.092c-10.325-3.14-19.743-6.697-27.905-10.534c-2.442-1.116-4.744-2.302-6.976-3.557
	c0,0-0.07,0-0.07-0.07c-2.441-1.187-4.744-2.512-6.906-3.907C8.65,121.972,0,110.95,0,99.3C0,61.907,88.807,31.56,198.264,31.561
	S396.457,61.908,396.457,99.3z"
      />
      <path
        fill="#F6C358"
        d="M197.642,148.11c-1.969,0-3.845-0.003-5.774-0.062c-9.181-0.074-18.224-0.391-27.325-0.949
	c-2.221-0.132-4.323-0.259-6.421-0.45l-0.856-0.077h-0.351c-1.793-0.114-3.506-0.274-5.256-0.431
	c-10.287-0.9-19.049-1.907-27.377-3.13c-1.879-0.231-3.195-0.45-4.504-0.659l-3.848-0.592c-1.393-0.225-4.973-0.881-4.973-0.881
	c-8.999-1.531-17.641-3.367-26.628-5.653c-2.104-0.555-4.372-1.155-6.504-1.756l-6.636-1.99c-7.832-2.382-15.047-5.006-21.496-7.813
	v-0.077l-10.555-5.13l-3.346-1.882h-0.136c-0.508-0.302-1.017-0.619-1.522-0.946c-11.276-7.086-15.204-13.205-15.204-16.332
	c0-17.207,68.085-48.811,179.334-48.811c111.203,0,179.263,31.604,179.263,48.811c0,3.112-3.897,9.178-14.878,16.033
	c-2.326,1.516-3.962,2.477-5.499,3.247l-1.023,0.551c-1.756,1.02-3.639,1.913-5.62,2.878c-6.806,3.198-15.377,6.424-25.316,9.514
	l-6.553,1.947c-2.138,0.601-4.338,1.202-6.605,1.799c-8.824,2.243-17.466,4.079-26.253,5.573c-1.932,0.351-3.688,0.672-5.447,0.949
	l-3.802,0.585c-1.235,0.203-2.468,0.41-3.753,0.561c-8.867,1.3-17.632,2.305-27.325,3.149c-2.012,0.185-3.941,0.36-5.912,0.487
	l-1.208,0.077c-2.098,0.191-4.135,0.317-6.294,0.444c-9.157,0.564-18.199,0.881-26.943,0.946c-2.357,0.068-4.338,0.071-6.341,0.071
	C198.594,148.11,197.824,148.11,197.642,148.11z"
      />
      <g>
        <path
          fill="#DC8744"
          d="M37.811,139.064l0,53.158c-2.442-1.116-4.744-2.302-6.976-3.558c0,0-0.07,0-0.07-0.07
		c-2.441-1.185-4.744-2.51-6.906-3.906l0-53.158c2.163,1.395,4.465,2.72,6.906,3.907c0,0.07,0.07,0.07,0.07,0.07
		C33.067,136.762,35.369,137.947,37.811,139.064z"
        />
        <path
          fill="#DC8744"
          d="M79.668,153.574l0,53.159c-2.372-0.628-4.744-1.256-6.976-1.884h-0.07
		c-2.372-0.698-4.604-1.395-6.906-2.093l0-53.158c2.302,0.697,4.534,1.396,6.906,2.092h0.07
		C74.924,152.318,77.296,152.947,79.668,153.574z"
        />
        <path
          fill="#DC8744"
          d="M121.525,161.736l0,53.158c-2.372-0.279-4.674-0.697-6.976-1.046h-0.07
		c-2.372-0.349-4.604-0.768-6.906-1.186l0-53.158c2.302,0.419,4.534,0.837,6.906,1.185h0.07
		C116.851,161.039,119.153,161.458,121.525,161.736z"
        />
        <path
          fill="#DC8744"
          d="M163.382,165.992l0,53.159c-2.372-0.14-4.674-0.279-6.976-0.489h-0.07
		c-2.372-0.14-4.604-0.349-6.906-0.559l0-53.158c2.302,0.21,4.534,0.418,6.906,0.558h0.07
		C158.708,165.712,161.01,165.852,163.382,165.992z"
        />
        <path
          fill="#DC8744"
          d="M205.239,166.968l0,53.158c-2.302,0.07-4.605,0.07-6.976,0.07h-0.07c-2.372,0-4.604,0-6.906-0.07
		l0-53.158c2.302,0.07,4.534,0.07,6.906,0.07h0.07C200.635,167.038,202.937,167.038,205.239,166.968z"
        />
        <path
          fill="#DC8744"
          d="M247.096,164.946l0,53.158c-2.302,0.209-4.605,0.419-6.976,0.559h-0.07
		c-2.302,0.21-4.535,0.349-6.906,0.489l0-53.159c2.372-0.14,4.604-0.279,6.906-0.488h0.07
		C242.492,165.364,244.794,165.155,247.096,164.946z"
        />
        <path
          fill="#DC8744"
          d="M288.954,159.504l0,53.158c-2.302,0.418-4.605,0.837-6.976,1.186h-0.07
		c-2.302,0.348-4.535,0.766-6.906,1.046l0-53.158c2.372-0.278,4.604-0.697,6.906-1.047h0.07
		C284.349,160.341,286.651,159.923,288.954,159.504z"
        />
        <path
          fill="#DC8744"
          d="M330.811,149.598l0,53.158c-2.302,0.698-4.605,1.395-6.976,2.093h-0.07
		c-2.232,0.628-4.535,1.255-6.906,1.884l0-53.159c2.372-0.627,4.674-1.255,6.906-1.884h0.07
		C326.206,150.994,328.508,150.295,330.811,149.598z"
        />
        <path
          fill="#DC8744"
          d="M372.668,131.39l0,53.298c-2.163,1.326-4.465,2.721-6.976,3.976c0,0-0.07,0-0.07,0.07
		c-2.163,1.186-4.465,2.303-6.906,3.488l0-53.158c2.441-1.186,4.744-2.302,6.906-3.557
		C368.133,134.25,370.436,132.855,372.668,131.39z"
        />
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </SvgIcon>
  );
}
