import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { ARCHIVE_COMPANY, COMPANY } from "../../queries-mutations/companies";
import AddNewButton from "../../components/AddNewButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import EditCustomerModal from "../../components/EditCustomerModal";
import Employee_ProductModal from "../../components/Employee_ProductModal";
import CustomersTable from "../../components/CustomersTable";
import MultiNewCustomerModal from "../../components/MultiNewCustomerModal";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AdminOrderOverview from "../../components/AdminOrderOverview";
import ConfirmationRequiredButton from "../../components/ConfirmationRequiredButton";
import SettingsIcon from "@material-ui/icons/Settings";
import NewCompanyModal from "../../components/NewCompanyModal";
import Button from "@material-ui/core/Button";

const styles = makeStyles(theme => ({
  margin: {
    marginBottom: 20
  },
  pdf: {
    height: "100%"
  },
  printIcon: {
    fontSize: 35
  },
  downloadLink: {
    color: "black",
    textDecoration: "none !important",
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center"
  }
}));

export default function SingleBedrijf() {
  let { id } = useParams();
  const classes = styles();

  const [state, setState] = useState({
    firsttax: [],
    secondtax: [],
    total:'',
  });
  
  const { loading, error, data, refetch } = useQuery(COMPANY, {
    variables: { id: id }
  });
  const [archiveCompany] = useMutation(ARCHIVE_COMPANY);

  const [modal, setModal] = React.useState();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [initiatedArchive, setInitiatedArchive] = useState(false);

  const initiateArchive = () => {
    setInitiatedArchive(true);
  };

  const cancelArchive = () => {
    setInitiatedArchive(false);
  };

  const confirmArchive = () => {
    archiveCompany({ variables: { id: id } });
    setTimeout(function() {
      refetch();
    }, 750);
  };

  if (loading) {
    return (
      <Paper>
        <p>Laden...</p>
      </Paper>
    );
  } else if (error) {
    return (
      <Paper>
        <p>Er ging iets mis</p>
      </Paper>
    );
  } else {
    const codeExists = code => {
      return data.company.employees.some(employee => employee.code === code);
    };

    const handleClickNew = event => {
      setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
      setAnchorEl(null);
    };

    const handleCloseNewModal = () => {
      setModal(<div />);
      setTimeout(function() {
        refetch();
      }, 500);
    };

    const manualNewCustomer = () => {
      setModal(
        <EditCustomerModal
          open={true}
          onClose={handleCloseNewModal}
          companyId={data.company.id}
          companyCode={data.company.code}
          codeExists={codeExists}
          duiten={data.company.duiten}
        />
      );
    };

    const MultiNewCustomer = () => {
      setModal(
        <MultiNewCustomerModal
          open={true}
          onClose={handleCloseNewModal}
          companyId={data.company.id}
          companyCode={data.company.code}
          codeExists={codeExists}
          duiten={data.company.duiten}
        />
      );
    };

    const onRowClick = clickedCustomer => {
      setModal(
        <EditCustomerModal
          open={true}
          onClose={handleCloseNewModal}
          customer={clickedCustomer}
        />
      );
    };

    
    const Onproductclick = clickedCustomer => {
      setModal(
        <Employee_ProductModal
          open={true}
          onClose={handleCloseNewModal}
          customer={clickedCustomer}
        />
      );
    };

    const MultiProduct = clickedCustomer => {
      console.log(clickedCustomer);
      setModal(
        <Employee_ProductModal
          open={true}
          onClose={handleCloseNewModal}
          customer={clickedCustomer}
        />
      );
    };

    if(data.company.employees){
    var product = data.company.employees.map(order => order.order).flat().map(prod => prod.products).flat();
    console.log(product, "product");
    state.firsttax = [];
    state.secondtax = [];
    product.map (prod => {
      if (prod.hasProduct !== null && typeof prod.hasProduct === 'object') {
        var productprice = prod.price;
        prod.hasProduct['productprice'] = productprice;
      }
    })
    var product = product.map(product => product.hasProduct).flat();
    product.map(prod => {
      //console.log(prod);
      if (prod !== null && typeof prod === 'object') {
        if(prod.taxpercent == 9){
          state.firsttax.push({price:prod.productprice});
        }
        else if(prod.taxpercent == 21){
          state.secondtax.push({price:prod.productprice});
        }
      }
    })
    state.firsttax = state.firsttax.reduce(function(prev, current) {
      return prev + +current.price
    }, 0);
    state.secondtax = state.secondtax.reduce(function(prev, current) {
      return prev + +current.price
    }, 0);
  }
  state.total = state.firsttax + state.secondtax;

    return (
      <div>
        <Paper className={classes.margin}>
          <h1>{data.company.name}</h1>
          <Typography>Aantal duiten: {data.company.duiten}</Typography>
          <Typography>Einddatum: {data.company.endDate}</Typography>
          <Typography>Code: {data.company.code}</Typography>
          <Typography>Email ID: {data.company.emailId}</Typography>
          <Typography>
            Gearchiveerd: {data.company.archived ? "Ja" : "Nee"}
          </Typography>
          <Typography>
          Totaal: {state.total} besteed
          </Typography>
          <Typography>
          {state.firsttax} duiten tegen een tarief van 9 %
          </Typography>
          <Typography>
          {state.secondtax} duiten tegen een tarief van 21 %
          </Typography>
          <Button
            onClick={() => {
              setModal(
                <NewCompanyModal
                  open={true}
                  onClose={handleCloseNewModal}
                  edit={true}
                  company={data.company}
                />
              );
            }}
          >
            Bewerken
          </Button>
          <ConfirmationRequiredButton
            initiated={initiatedArchive}
            cancel={cancelArchive}
            initiate={initiateArchive}
            confirm={confirmArchive}
            confirmationMessage={
              "Weet je zeker dat je dit bedrijf wilt archiveren? Dit kan niet ongedaan gemaakt worden. Alle persoonlijke gegevens zullen worden verwijderd."
            }
            cancelMessage={"Annuleren"}
            confirmMessage={"Archiveren"}
            initialMessage={"Archiveren"}
          />
        </Paper>
        <CustomersTable
          employees={data.company.employees}
          onRowClick={onRowClick}
          Onproductclick={Onproductclick}
          MultiProduct={MultiProduct}
        />
        <AdminOrderOverview company={data.company} />
        <AddNewButton icon={<SettingsIcon />} onClick={handleClickNew} />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={manualNewCustomer}>
            Handmatig persoon toevoegen
          </MenuItem>
          <MenuItem onClick={MultiNewCustomer}>
            Importeer personen via csv
          </MenuItem>
        </Menu>
        {modal}
      </div>
    );
  }
}
