import { cartActions } from "../actions";

const cart = (state = [], action) => {
  switch (action.type) {
    case cartActions.ADD_TO_CART:
      return [
        ...state,
        {
          id: action.id,
          name: action.name,
          price: action.price,
          img: action.img,
          newImg: action.newImg
        }
      ];
    case cartActions.REMOVE_FROM_CART:
      return [
        ...state.slice(0, action.index),
        ...state.slice(action.index + 1)
      ];
      case cartActions.UPDATE_TO_CART:
        let val = state[action.index]
        val.stock = true;

        return [
          ...state
          
        ]
    case cartActions.EMPTY_CART:
      return [];
    default:
      return state;
  }
};

export default cart;
