import React, { useState } from "react";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_CONTACT_FORM } from "../queries-mutations/customers";
import { emailRegex, phoneRegex } from "../helpers/regex";

const styles = makeStyles(theme => ({
  input: {
    minWidth: "40%",
    marginTop: 5,
    marginBottom: 5
  }
}));

export default function VragenFormulier() {
  const classes = styles();
  const [submitted, setSubmitted] = useState(false);
  const [state, setState] = useState({});
  const [emailError, setEmailError] = useState({ error: false, message: "" });
  const [phoneError, setPhoneError] = useState({ error: false, message: "" });

  const [createContactForm] = useMutation(CREATE_CONTACT_FORM);

  const handleTextChange = event => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleEmailChange = event => {
    let email = event.target.value;
    setState({ ...state, email: email });
    if (!emailRegex.test(email)) {
      setEmailError({ error: true, message: "Ongeldig email adres" });
    } else {
      setEmailError({ error: false, message: "" });
    }
  };

  const handlePhoneChange = event => {
    let phone = event.target.value;
    setState({ ...state, phone: phone });
    if (!phoneRegex.test(phone)) {
      setPhoneError({ error: true, message: "Ongeldig telefoonnummer" });
    } else {
      setPhoneError({ error: false, message: "" });
    }
  };

  const submit = () => {
    setSubmitted(true);
    createContactForm({
      variables: {
        name: state.name,
        phone: state.phone,
        email: state.email,
        employer: state.employer,
        code: state.code,
        details: state.details
      }
    });
  };

  return (
    <div>
      {submitted ? (
        <h3>
          Je verzoek is verstuurd en er word spoedig contact met je opgenomen.
        </h3>
      ) : (
        <form
          onSubmit={e => {
            e.preventDefault();
            submit();
          }}
        >
          <h4>
            Wil je contact met ons opnemen? Vul dan onderstaande formulier in.
          </h4>
          <TextField
            className={classes.input}
            id="name"
            name="name"
            label="Naam"
            required
            onChange={handleTextChange}
          />
          <br />
          <TextField
            className={classes.input}
            id="phone"
            name="phone"
            label="Telefoonnummer"
            required
            onChange={handlePhoneChange}
            helperText={phoneError.message}
            error={phoneError.error}
          />
          <br />
          <TextField
            className={classes.input}
            id="email"
            name="email"
            label="E-mailadress"
            required
            onChange={handleEmailChange}
            helperText={emailError.message}
            error={emailError.error}
          />
          <br />
          <TextField
            className={classes.input}
            id="employer"
            name="employer"
            label="Werkgever"
            required
            onChange={handleTextChange}
          />
          <br />
          <TextField
            className={classes.input}
            id="code"
            name="code"
            label="Logincode"
            required
            onChange={handleTextChange}
          />
          <br />
          <TextField
            className={classes.input}
            id="details"
            name="details"
            label="Verzoek"
            required
            multiline
            rows={4}
            onChange={handleTextChange}
          />
          <br />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={emailError.error || phoneError.error}
          >
            Verstuur
          </Button>
        </form>
      )}
    </div>
  );
}
