import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "@apollo/react-hooks";
import { CART_SUGGESTIONS } from "../queries-mutations/products";
import SingleProductSmall from "./SingleProductSmall";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";

const styles = makeStyles((theme) => ({
  text: {
    color: theme.palette.text.main,
    textAlign: "left",
    marginLeft: 20,
  },
  productsContainer: {
    // display: 'flex',
    // flexBasis: "20%",
    border: "1px solid green"
  },
}));

export default function CartSuggestions(props) {
  const classes = styles();
  const { loading, error, data , refetch} = useQuery(CART_SUGGESTIONS );
  const value = useQuery(CART_SUGGESTIONS);
  //console.log(value);

  useEffect(() => {
  
      refetch();

  },[props.cart])
  if (data) {
    let best = data.cartSuggestions
      .slice(0, 5)
      .map(
        (product) =>{
        console.log(product);
         //console.log("test");
        return  product.published &&
          product.price <= props.userInformation.duiten && (     
          <SingleProductSmall
              newImg={product.images ? product.images[0].new : false}
              img={product.images ? product.images[0].location : null}
              name={product.name}
              price={product.price}
              id={product.id}
            />
          )
        }
      );
    return (
      <Grid container className={classes.container}>
        <Grid xs={12}>
          <h3 className={classes.text}>
            Hieronder geven we wat suggesties voor je laatste Duit:
          </h3>
        </Grid>
        <Grid container className={classes.productsContainer}>
          {best}
        </Grid>
      </Grid>
    );
  } else if (loading) {
    return (
      <div>
        <CircularProgress color="primary" />
      </div>
    );
  } else if (error) {
    return (
      <div>
        <h2>Er ging iets mis, probeer het later opnieuw</h2>
      </div>
    );
  }
}
// const mapStateToProps = (state) => {
//   const { userInformation } = state;
//   return { userInformation };
// };

// export default connect(mapStateToProps)(CartSuggestions);
