import { makeStyles } from "@material-ui/core/styles";

export const HeaderTextStyles = makeStyles(theme => ({
  Text: {
    fontSize: 12
  },
  hideOnMobile: {
    "@media (max-width: 768px)": {
      display: "none"
    }
  }
}));
