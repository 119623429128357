import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import SingleOrderInfoAdmin from "./SingleOrderInfoAdmin";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { Document, PDFDownloadLink } from "@react-pdf/renderer";
import SingleOrderPickerList from "../helpers/SingleOrderPickerList";
import { useMutation } from "@apollo/react-hooks";
import { PROCESS_ORDER } from "../queries-mutations/customers";
import IconButton from "@material-ui/core/IconButton";

const styles = makeStyles(theme => ({
  container: {
    padding: 15
  },
  userInfo: {
    textAlign: "left"
  },
  warningIcon: {
    fontSize: 35,
    color: "red"
  },
  processedIcon: {
    fontSize: 35,
    color: "green"
  },
  unprocessedIcon: {
    fontSize: 35,
    color: "black"
  },
  unfinishedIcon: {
    fontSize: 35,
    color: "orange"
  },
  error: {
    color: "red"
  },
  warning: {
    color: "orange"
  },
  margin: {
    marginBottom: 20
  }
}));

export default function SingleUserInfoAdmin(props) {
  console.log(props)
  const classes = styles();

  const [processOrder] = useMutation(PROCESS_ORDER);

  return (
    <Paper className={classes.margin}>
      <Grid container className={classes.container}>
        <Grid item xs={3}>
          <Typography className={classes.userInfo}>
            {props.data.firstName}{" "}
            {props.data.insertion && props.data.insertion.length > 0
              ? `${props.data.insertion} `
              : ""}
            {props.data.lastName} <br />
            {props.data.code} <br />
            {props.data.email} <br />
            {props.data.order.some(order => order.processed) &&
              props.data.order.some(order => order.processed === false) && (
                <Typography className={classes.error}>
                  Let op! Een deel van deze order is al WEL verwerkt
                </Typography>
              )}
            {props.data.duiten > 0 ? (
              <Typography className={classes.warning}>
                Deze gebruiker heeft nog {props.data.duiten} duiten te besteden
                en is mogelijk nog niet klaar
              </Typography>
            ) : (
              <Typography>Deze gebruiker heeft geen duiten meer.</Typography>
            )}
            <PDFDownloadLink
              document={
                <Document>
                  <SingleOrderPickerList
                    companyName={props.companyName}
                    companyDuiten={props.companyDuiten}
                    firstName={props.data.firstName}
                    insertion={props.data.insertion}
                    lastName={props.data.lastName}
                    duiten={props.data.duiten}
                    code={props.data.code}
                    email={props.data.email}
                    orders={props.data.order}
                  />
                </Document>
              }
              fileName={
                props.companyName +
                " " +
                props.data.lastName +
                ", " +
                props.data.firstName
              }
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Laden..."
                ) : (
                  <IconButton
                    onClick={() =>
                      // eslint-disable-next-line array-callback-return
                      props.data.order.map(order => {
                        processOrder({ variables: { id: order.id } });
                      })
                    }
                  >
                    <ListAltIcon
                      className={
                        props.data.order.some(order => order.processed) &&
                        props.data.order.some(
                          order => order.processed === false
                        )
                          ? classes.warningIcon
                          : props.data.order.some(order => order.processed)
                          ? classes.processedIcon
                          : props.data.duiten > 1
                          ? classes.unfinishedIcon
                          : classes.unprocessedIcon
                      }
                    />
                  </IconButton>
                )
              }
            </PDFDownloadLink>
          </Typography>
        </Grid>
        <Grid item xs={9}>
          {props.data.order.map(order => (
            <SingleOrderInfoAdmin data={order} />
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
}
