import React from "react";
import "./App.css";
import { Provider } from "react-redux";
import rootReducer from "./reducers";
import { createStore } from "redux";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import ApolloContainer from "./components/ApolloContainer";
import TagManager from "react-gtm-module";

function App() {
  const persistConfig = {
    key: "root",
    storage
  };

  const tagManagerArgs = {
    gtmId: "GTM-N6NB2TK"
  };

  TagManager.initialize(tagManagerArgs);

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  let store = createStore(
    persistedReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );

  let persistor = persistStore(store);

  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ApolloContainer />
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
