import React from "react";
import Paper from "@material-ui/core/Paper";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DownloadAllOrderPickerLists from "./DownloadAllOrderPickerLists";
import { Typography } from "@material-ui/core";
import SingleUserInfoAdmin from "./SingleUserInfoAdmin";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { PROCESS_ORDER } from "../queries-mutations/customers";

const styles = makeStyles(theme => ({
  margin: {
    marginBottom: 20
  },
  pdf: {
    height: "100%"
  },
  printIcon: {
    fontSize: 35
  },
  downloadLink: {
    color: "white",
    textDecoration: "none !important",
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center"
  },
  checkbox: {
    marginTop: 15
  }
}));

export default function AdminOrderOverview(props) {
  const classes = styles();

  const [showProcessed, setHideProcessed] = React.useState(false);

  const [processOrder] = useMutation(PROCESS_ORDER);

  const handleChange = event => {
    setHideProcessed(event.target.checked);
  };

  return (
    <div>
      <Paper className={classes.margin}>
        <h1>Orders</h1>
        <PDFDownloadLink
          document={
            <DownloadAllOrderPickerLists
              data={props.company}
              showProcessed={showProcessed}
            />
          }
          fileName={props.company.name}
        >
          <Typography
            className={classes.downloadLink}
            onClick={() => {
              props.company.employees.map(orderPickerList => {
                orderPickerList.order[0] &&
                  !orderPickerList.order[0].processed &&
                  processOrder({
                    variables: { id: orderPickerList.order[0].id }
                  });
              });
            }}
            style={{
              textDecoration: "none",
              marginBottom: 20,
              border: "1px solid #0d7c5f",
              borderRadius: 5,
              padding: "10px 15px",
              color: "white",
              backgroundColor: "#0d7c5f"
            }}
          >
            Download orderpicker lijsten
          </Typography>
        </PDFDownloadLink>
        <br />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              checked={showProcessed}
              onChange={handleChange}
              checkedIcon={<VisibilityIcon />}
              icon={<VisibilityOffIcon />}
              name="checkedH"
            />
          }
          label={
            showProcessed
              ? "Verwerkte orders zichtbaar"
              : "Verwerkte orders verborgen"
          }
        />
      </Paper>
      {props.company.employees.map(
        employee =>
          employee.order.length > 0 &&
          (showProcessed ? (
            <SingleUserInfoAdmin
              hideProcessed={showProcessed}
              companyName={props.company.name}
              companyDuiten={props.company.duiten}
              companyEmail={props.company.emailId}
              data={employee}
            />
          ) : (
            employee.order.some(order =>{
              return !order.processed}) && (
              <SingleUserInfoAdmin
                hideProcessed={showProcessed}
                companyName={props.company.name}
                companyDuiten={props.company.duiten}
                companyEmail={props.company.emailId}
                data={employee}
              />
            )
          ))
      )}
    </div>
  );
}
